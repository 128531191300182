import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./App.css"
// layout
import MainpageLayout from "./layouts/MainpageLayout"
// pages
import Homepage from "./pages/Homepage"
import ADpage from "./pages/ADpage"
import TestPage from "./pages/TestPage"

const router = createBrowserRouter([  
  {
    path: "/",
    element: <MainpageLayout current="home" />,
    children: [
      {
        path: ":state/:suburb/:name/:language", 
        element: <ADpage />,
      },
    ],
  },
  {
    path: "/test",
    element: <MainpageLayout current="home" />,
    children: [
      {
        path: "", 
        element: <Homepage />,
      },
    ],
  },
  {
    path: "/s",
    element: <TestPage />
  }
])

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  )
}

export default App
