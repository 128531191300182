import React, { useState, useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import "./styles.css";

import ApiService from "../../services"
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { LeftOutlined, RightOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Space, message, Avatar, Divider, Row, Col, Carousel, Image, Input, List, Flex, Tag } from "antd";
const { TextArea } = Input;

const ADpage = (props: any) => {
  const { state, suburb, name, language } = useParams(); 
  const navigate = useNavigate();
  const [adPost, setADPost] = useState();

  useEffect(() => {
    console.log("ADpage", adPost);
          
    ApiService.getADPost(name)
    .then((resp) => {
      console.log("getADPost Success:", resp.data);
      setADPost(resp.data);
    })
    .catch((err) => {
      console.log("Error:", err);
      message.error(err.message);
    });
  }, []);

  const customPrevArrow = <LeftOutlined style={{ fontSize: '24px', color: 'red' }} />;
  const customNextArrow = <RightOutlined style={{ fontSize: '24px', color: 'red' }} />;

  const gen_photo = (url_list:any[]) => {
    return (
      <div style={{textAlign:"center", backgroundColor:"white"}}>
      <Carousel  arrows={true} prevArrow={customPrevArrow} nextArrow={customNextArrow}>
        {url_list.map((url_item, index) =>{
          return (
            <div key={index} style={{height:"100%"}}>
              <Image style={{padding:"20px", margin:"auto", height:"100%"}} src={`${import.meta.env.VITE_APP_API_HOST}/${url_item.upload}`} />
              {/* <Image style={{padding:"20px", margin:"auto", height:"100%"}} src={`${import.meta.env.VITE_APP_Test}/${url_item.upload}`} /> */}
            </div>
          )
        })}
      </Carousel>
    </div>
    )
  }

  const gen_info = (adPost:any) => {
    var item_list:any[] = [
      {label: "Phone", value: adPost.phone},
      {label: "State", value: adPost.state},
      {label: "Suburb", value: adPost.suburb},
    ];
    for (var key in adPost.attrs) {
      if (key === "services") continue;
      if (adPost.attrs.hasOwnProperty(key)) { // 确保只遍历对象本身的属性，而不包括原型链上的属性
        var key_value = adPost.attrs[key];
        item_list.push({label: key, value: key_value})
      }
    }

    var services_items = [];
    if ('services' in adPost.attrs) services_items = adPost.attrs.services;

    var desc_item:any[] = [
      language==='cn'? adPost.desc_cn : adPost.desc_en
    ];

    return (
      < >
        {/* <Space direction="vertical" >maxWidth:"700px",  */}
          {/* <List
        bordered
        header={<div style={{fontWeight:"bold"}}>Quick Facts</div>}
        dataSource={item_list}
        itemLayout="horizontal"
        renderItem={(item) => {
          return (
            <List.Item>
               <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
                <div >{item.label}</div> <div>{item.value}</div>
              </div>       
            </List.Item>
          )
        }}
      /> */}
      <List
        bordered
        header={<div style={{fontWeight:"bold"}}>About Me</div>}
        dataSource={desc_item}
        itemLayout="horizontal"
        renderItem={(item) => {
          return (
            <List.Item>
               <div style={{whiteSpace:"pre-wrap"}}>{item}</div>       
            </List.Item>
          )
        }}
      />
      {/* {services_items.length>0 && (
        <List
          bordered
          header={<div style={{fontWeight:"bold"}}>Services</div>}
          itemLayout="horizontal"
        >
          <List.Item>
          <Flex gap="4px 0" wrap="wrap">
            {services_items.map((item:any, index:number) =>{
              return (<Tag color="#f50" key={index}>{item}</Tag>)
            })}
          </Flex>       
          </List.Item>
        </List>
      )} */}
      {/* </Space> */}
      </>
    );
  };

  const gen_ADPost = (adPost:any) => {
    return (
      <div style={{margin:"10px"}}>
        <Row>
          <Col>
          <Space>
            <Avatar size={82} src={`${import.meta.env.VITE_APP_API_HOST}/${adPost.avatar_url}`} />
            <h1>{adPost.name}{adPost.name_index>1?'_'+adPost.name_index:''}</h1>
            {/* <span>{adPost.phone}</span> */}
          </Space>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>{gen_photo(adPost.media_urls)}</Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>{gen_info(adPost)}</Col>
        </Row>  
      </div>
    )
  };

  return (
    <div style={{maxWidth:"1200px", margin:"10px auto"}}>
      {adPost && gen_ADPost(adPost)}
    </div>
  );
};

export default ADpage;
