// import React from "react"
// import ReactDOM from "react-dom/client"
// import { Provider } from "react-redux"
// import { store } from "./app/store"
// import App from "./App"
// import "./index.css"

// ReactDOM.createRoot(document.getElementById("root")!).render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
// )


import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import { ConfigProvider } from "antd";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#FF8C00",
          },
          components: {
            Typography: {
              colorTextHeading: "#555555",
              // colorTextHeading: "#ffa629",
              fontSizeLG: 18,
              margin: 50,
            },
          },
        }}
      >
        {/* <BrowserRouter> */}
          <App />
        {/* </BrowserRouter> */}
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

