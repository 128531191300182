import React from "react";

import "./styles.css";

const WebFooter = (props: any) => {
  return (
    <div className="bg-footer">
      <div className="footer">
        <div className="footerwrap">© 2024 by Creative IT Group PTY LTD. All rights reserved.</div>
      </div>
    </div>
  );
};

export default WebFooter;

// export default function WebFooter(props:any) {

//   return (
//     <>d</>
//   );
// }
