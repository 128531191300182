import React, { useState, useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import "./styles.css";

import ApiService from "../../services"
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { UserOutlined, DownOutlined, SmileOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Space, message, Avatar, Divider, Row, Col, Carousel, Image } from "antd";

const TestPage = (props: any) => {
  
  const navigate = useNavigate();
  

  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };

  
  return (
    <div className="main-content">
      <Carousel arrows infinite={false}>
      <div>
        <h3 style={contentStyle}>1</h3>
      </div>
      <div>
        <h3 style={contentStyle}>2</h3>
      </div>
      <div>
        <h3 style={contentStyle}>3</h3>
      </div>
      <div>
        <h3 style={contentStyle}>4</h3>
      </div>
    </Carousel>
    </div>
  );
};

export default TestPage;
