import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./styles.css";

import ApiService from "../../services" 
import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { UserOutlined, DownOutlined, SmileOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Space, List, message, Avatar, Divider } from "antd";

const Homepage = (props: any) => {
  const navigate = useNavigate();
  const [urls, setURLs] = useState<any[]>([]);

  useEffect(() => {
    console.log("Homepage", urls);
          
    ApiService.getUrlList()
    .then((resp) => {
      console.log("getUrlList Success:", resp.data);
      setURLs(resp.data);
    })
    .catch((err) => {
      console.log("Error:", err);
      message.error(err.message);
    });
  }, []);

  return (
    <div className="main-content">
      {/* <div>Home Page</div> */}
      <List
        bordered
        dataSource={urls}
        itemLayout="horizontal"
        renderItem={(item) => {
          let url = `${import.meta.env.VITE_APP_DOMAIN}/${item.state}/${item.suburb}/${item.name}${item.name_index>1?'_'+item.name_index:''}`
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={`${import.meta.env.VITE_APP_API_HOST}/${item.avatar_url}`} />}
                description={
                  <Space style={{float:"left"}} direction="vertical">
                  {item.active_cn ? <a href={`${url}/cn`}>{`${url}/cn`}</a>: ""}
                  {item.active_en ? <a href={`${url}/en`}>{`${url}/en`}</a>: ""}  
                  </Space>
                }
              />              
            </List.Item>
          )
        }}
      />
      <Divider />
    </div>
  );
};

export default Homepage;
