import React from "react"
import { Outlet, useParams } from "react-router-dom"
import { WebHeader, WebFooter } from "../../components"

import "./style.css"
import { Typography, Form, Input } from "antd"
const { Text, Link } = Typography;

const MainpageLayout = (props: any) => {

  return (
    <div className="main-page">
      <div className="main-page-content">
        <WebHeader {...props} />
        <Outlet />        
        <WebFooter />
      </div>
    </div>
  )
}

export default MainpageLayout