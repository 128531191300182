import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./styles.css";
import ApiService from "../services"
import { useAppSelector, useAppDispatch } from "../app/hooks"

import type { MenuProps } from "antd";
import { DownOutlined, UserOutlined, HourglassOutlined, LoginOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Button, message } from "antd";

const WebHeader = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState(props.current);
  useEffect(() => {
    setCurrent(props.current);
  }, [props.current]);

  return (
    <div className="navMain">      
      <div className="navLogoColumn" onClick={()=>navigate("/")}>
        <img className="logo" src="/images/icons/iinearby.png"></img>
        {/* <div className="navTitle">
          <div className="navTitleRow">{import.meta.env.VITE_APP_TITLE}</div>
          <div className="navSubTitleRow">{import.meta.env.VITE_APP_SUBTITLE}</div>
        </div> */}
      </div>

      <div className="navColumn">
        {/* <div className={current==='home' ? "navMenuItem navMenuItemFocus" : "navMenuItem"} onClick={() => navigate("/")}>
          Home
        </div> */}
               
      </div>
    </div>
  );
};

export default WebHeader;
