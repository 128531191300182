import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import qs from 'qs';
import "./Config";

class ApiService {
  getUrlList =  async () => {
    return await axios.get(`api/posts/`);
  };
  getADPost =  async (name) => {
    let params = {
      name: name
    };
    return await axios.get(`api/posts/`, { params: params });
  };

  // // Authentication
  // signUp = async (data) => {
  //   return await axios.post(`api/signup/`, data
  //   // , { withCredentials: true }  // 允许发送和接收 cookie 
  //   );
  // };

}

export default new ApiService();

// TODO： 暂时不添加登录要求，后面再打开

// // Add a request interceptor
// axios.interceptors.request.use((config) => {
//   const user = JSON.parse(localStorage.getItem("user"));

//   if (user && user.access) {
//     const token = "Bearer " + user.access;
//     config.headers.Authorization = token;
//   }

//   return config;
// });

// // 添加响应拦截器--代码实现案例：仅供参考
// axios.interceptors.response.use(
//   function (response) {
//     // 当状态码为2xx/3xx开头的进这里
//     // 对响应数据做点什么
//     return response;
//   },
//   async function (error) {
//     // 响应状态码4xx/5xx进这里
//     // 对响应错误做点什么
//     console.log(`error: ${error.response.status}`);
//     if (error.response.status === 401) {
//       // 身份过期/token无效
//       // //  1.清空vuex的token
//       // store.commit("setToken", "");
//       // store.commit("setRefreshToken", "");
//       // 2. 清空本地token
//       localStorage.removeItem("user");
//       // 3. 跳转到登录页面登录
//       window.location.hash="/login";
//       // router.push({
//       //   path: "/login",
//       // });
//     }
//     return Promise.reject(error);
//   }
// );
